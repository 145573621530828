import React from "react"
import { Fragment } from "react"

export default function Input({
  rule,
  label,
  type,
  name,
  className,
  textarea,
  value,
  readOnly,
  register,
  field,
}) {
  if (!register) return <Fragment />

  return textarea ? (
    <div className={"grid gap-3 " + className}>
      <label htmlFor={name} className="pl-4 text-[16px] font-property">
        {label}
      </label>
      <textarea
        id={name}
        type={type}
        name={name}
        rules={rule}
        rows={8}
        placeholder={label}
        className="text-[20px] px-4 py-4 font-property bg-gray-50 outline-none focus:outline-none"
        {...register(field)}
      />
    </div>
  ) : (
    <div className={"grid gap-3 " + className}>
      <label
        htmlFor={name}
        className="pl-4 text-[16px] font-property col-start-1 row-start-1"
      >
        {label}
      </label>
      <input
        // disabled={readOnly ? readOnly : false}
        id={name}
        type={type}
        name={name}
        rules={rule}
        placeholder={label}
        // value={value ? value : null}
        className="text-[20px] px-4 py-4 font-property bg-gray-50 outline-none focus:outline-none col-start-1 row-start-2 z-0"
        {...register(field)}
      />
      {readOnly && (
        <div className="col-start-1 row-start-2 relative z-10 px-4 py-4 bg-opacity-30 bg-gray-300" />
      )}
    </div>
  )
}
